<template>
    <div class="app-container">
        <main>
            <h1>Redirecting to Your Destination...</h1>
            <p>You will be redirected to:</p>
            <p style="font-size: 2.0rem">
                <a href="https://whatley.xyz">whatley.xyz</a>
            </p>
            <p>in <span id="countdown">3</span> seconds.</p>
            <p>If you are not redirected, click the link above or use the button below.</p>
            <div class="spinner" role="status" aria-live="polite" aria-label="Loading..."></div>
            <div class="progress-bar"></div>
            <button class="redirect-btn" @click="manualRedirect">Redirect Now</button>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            redirectUrl: "https://whatley.xyz",
            countdown: 3,
            progress: 0,
            buttonDisabled: false,
            buttonLabel: "Redirect Now",
            intervalId: null,
            timeoutId: null,
        };
    },
    methods: {
        startCountdown() {
            this.intervalId = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                    this.progress += 100 / 3;
                } else {
                    clearInterval(this.intervalId);
                }
            }, 1000);

            this.timeoutId = setTimeout(() => {
                this.redirect();
            }, 3000);
        },
        redirect() {
            window.location.href = this.redirectUrl;
        },
        manualRedirect() {
            this.buttonDisabled = true;
            this.buttonLabel = "Redirecting...";
            this.redirect();
        },
    },
    mounted() {
        this.startCountdown();
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
    },
};
</script>

<style scoped>

.app-container {
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #282c34, #0d1117);
    margin: 0;
}

main {
    background-color: rgba(28, 31, 38, 0.95);
    border-radius: 16px;
    padding: 40px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
    max-width: 500px;
    width: 90%;
    text-align: center;
    position: relative;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #fbc02d;
}

p {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #e1e1e1;
}

a {
    color: #fbc02d;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

a:hover {
    color: #ffcc00;
}

.spinner {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top: 6px solid #fbc02d;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: #fbc02d;
    animation: progress 3s linear forwards;
}

@keyframes progress {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.redirect-btn {
    background-color: #21a1f1;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.redirect-btn:hover {
    background-color: #1a87d9;
    transform: translateY(-2px);
}

.redirect-btn:disabled {
    background-color: #555;
    cursor: not-allowed;
}

@media (max-width: 600px) {
    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
    }

    .spinner {
        width: 40px;
        height: 40px;
        border-width: 5px;
    }
}
</style>