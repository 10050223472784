<template>
  <RedirectPage />
</template>

<script>
import RedirectPage from "./components/RedirectPage.vue";

export default {
  components: {
    RedirectPage,
  },
};
</script>


<style>
/* Apply dark theme globally */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: linear-gradient(135deg, #282c34, #0d1117);
  /* Dark background */
  color: #e1e1e1;
  /* Light text color */
}
</style>